import { render, staticRenderFns } from "./ResultInfo.vue?vue&type=template&id=f81b2814&scoped=true&"
import script from "./ResultInfo.vue?vue&type=script&lang=js&"
export * from "./ResultInfo.vue?vue&type=script&lang=js&"
import style0 from "./ResultInfo.vue?vue&type=style&index=0&id=f81b2814&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f81b2814",
  null
  
)

export default component.exports